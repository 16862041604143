import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ProjectService } from '../services/project.service';

export const studyResolver: ResolveFn<any> = async (route, state) => {
  const projectService = inject(ProjectService);
  if(route.queryParams && route.queryParams['study']) {
    console.log(route.queryParams);
    const studyCode = route.queryParams['study'];
    const res: any = await projectService.getStudyByCode(studyCode);
    return res.data;
  }
  
};
