import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { VexConfigName } from '@vex/config/vex-config.interface';
import { VexConfigService } from '@vex/config/vex-config.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {

  constructor(private configService: VexConfigService) { }

  ngOnInit() { 
    this.configService.setConfig(VexConfigName.ikaros);
  }


}
