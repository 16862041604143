import { ResolveFn } from '@angular/router';
import { ProjectService } from '../services/project.service';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';

export const userResolver: ResolveFn<boolean> = async (route, state) => {
  const projectService = inject(ProjectService);
  const authService = inject(AuthService);
  const user = authService.currentUserValue;
  console.log(user);
  const res: any = await projectService.getStudyByCode(user.user.studyOption);
  console.log(res);
  return res;
};
