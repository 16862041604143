import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { LoaderComponent } from '../component/loader/loader.component';


@Injectable({
  providedIn: 'root',
})
export class RestService {
  baseUrl = environment.url;

  constructor(private http: HttpClient) {}

  async post(
    url: string,
    body: any,
    contentType = 'application/json'
  ): Promise<any> {
    //LoaderComponent.showLoader();
    return this.http
      .post(this.baseUrl + url, body, this.getHeaders(contentType))
      .toPromise()
      .then((response: any) => {
        //LoaderComponent.hideLoader();
        if (response && response.status == false) {
          throw new Error(response.message);
        }
        return Promise.resolve(response);
      })
      .catch((e) => {
       // LoaderComponent.hideLoader();
        return Promise.reject(e);
      });
  }

  async get(url: string, contentType = 'application/json'): Promise<any> {
   // LoaderComponent.showLoader();
    return this.http
      .get(this.baseUrl + url, this.getHeaders(contentType))
      .toPromise()
      .then((response: any) => {
       // LoaderComponent.hideLoader();
        if (response && response.status == false) {
          throw new Error(response.message);
        }
        return Promise.resolve(response);
      })
      .catch((e) => {
       // LoaderComponent.hideLoader();
        return Promise.reject(e);
      });
  }

  put(url: string, contentType = 'application/json'): Promise<any> {
    return this.http
      .put(this.baseUrl + url, this.getHeaders(contentType))
      .toPromise();
  }

  async postFormData(url: string, body: any, contentType = 'multipart/form-data;boundary=' + Math.random()): Promise<any> {
   // LoaderComponent.showLoader();
    return this.http.post(this.baseUrl + url, body).toPromise()
      .then((response) => {
       // LoaderComponent.hideLoader();
        return Promise.resolve(response)
      }).catch((e) => {
       // LoaderComponent.hideLoader();
        return Promise.reject(e)
      })

  }

  async getimg(url: string, contentType = 'application/json'): Promise<any> {
    // LoaderComponent.showLoader(); // Assuming this is for showing a loader, commented for clarity
    try {
      const response = await this.http
        .get(this.baseUrl + url, { headers: this.getHeaders(contentType), responseType: 'blob' })
        .toPromise();
      
      // LoaderComponent.hideLoader(); // Assuming this is for hiding a loader, commented for clarity
      
      if (response instanceof Blob) {
        return response;
      } else {
        // Handle unexpected response
        throw new Error('Unexpected response type');
      }
    } catch (error) {
      // LoaderComponent.hideLoader();
      return Promise.reject(error);
    }
  }
  
  private getHeaders(contentType: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': contentType,
        Accept: 'application/json, text/plain, */*',
      }),
    };
  }


}
