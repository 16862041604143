import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    static readonly nodeUser: string = 'user/';
    static readonly NodeLoginUser: string = 'user/login';

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient, private restService: RestService,
        private snackbar: MatSnackBar,
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    async login(username: string, password: string) {
        try {
            const body = {
                username: username,
                password: password
            }
            const res = await this.restService.post(AuthService.NodeLoginUser, body);
            if (res.success) {
                localStorage.setItem('currentUser', JSON.stringify(res.result));
                this.currentUserSubject.next(res.result);
                this.snackbar.open(res.message, 'close', {duration: 3000});
                return res.result
            }else{
                this.snackbar.open(res.message, 'close', {duration: 3000});
                return null
            }
        } catch (error) {
            console.log(error);
            this.snackbar.open(error.message, 'close', {duration: 3000});
            return null
        }
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
