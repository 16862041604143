import { DrRegisterComponent } from './dr-register/dr-register.component';
import { authGuard } from './helper/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { studyResolver } from './resolver/study.resolver';
import { userResolver } from './resolver/user.resolver';
import { Visit1Component } from './pages/crf-jalraod/visit1/visit1.component';
import { Visit4Component } from './pages/crf-jalraod/visit4/visit4.component';
import { Visit3Component } from './pages/crf-jalraod/visit3/visit3.component';
import { Visit2Component } from './pages/crf-jalraod/visit2/visit2.component';

export const appRoutes: VexRoutes = [
  {
    path: '',
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadComponent: () =>
          import('./pages/home/home.component').then(
            (m) => m.HomeComponent
          ),
          resolve: {
            study: userResolver
          }
          
      },
      {
        path: '',
        loadComponent: () =>
          import('./dr-register/dr-register.component').then(
            (m) => m.DrRegisterComponent
          ),
          resolve: {
            study: userResolver
          }
          
      },
      {
        path: 'user',
        loadComponent: () =>
          import('./pages/admin/user/user.component').then(
            (m) => m.UserComponent
          )
      },

      {
        path: 'study',
        loadComponent: () =>
          import('./pages/admin/study/study.component').then(
            (m) => m.StudyComponent
          )
      },
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./pages/admin/dashboard/dashboard.component').then(
            (m) => m.DashboardComponent
          )
      },

      {
        path: 'doctor',
        loadComponent: () =>
          import('./pages/admin/doctor/doctor.component').then(
            (m) => m.DoctorComponent
          )
      },

      {
        path: 'crfdata',
        loadComponent: () =>
          import('./pages/crf-data/crf-data.component').then(
            (m) => m.CrfDataComponent
          )
      },

      {
        path: 'crft2dm',
        loadComponent: () =>
          import('./pages/crf-t2dm/crf-t2dm.component').then(
            (m) => m.CrfT2dmComponent
          )
      },

      {
        path: 'crftazloc',
        loadComponent: () =>
          import('./pages/crf-tazloc/crf-tazloc.component').then(
            (m) => m.CrfTazlocComponent
          )
      },

      {
        path: 'crfriccs',
        loadComponent: () =>
          import('./pages/crf-riccs/crf-riccs.component').then(
            (m) => m.CrfRiccsComponent
          )
      },

      {
        path: 'crfhills',
        loadComponent: () =>
          import('./pages/crf-hills/crf-hills.component').then(
            (m) => m.CrfHillsComponent
          )
      },

      {
        path: 'crftriglynase',
        loadComponent: () =>
          import('./pages/crf-triglynase/crf-triglynase.component').then(
            (m) => m.CrfTriglynaseComponent
          )
      },

      {
        path: 'crfudapa',
        loadComponent: () =>
          import('./pages/crf-udapa/crf-udapa.component').then(
            (m) => m.CrfUdapaComponent
          )
      },

      {
        path: 'crfglycomet',
        loadComponent: () =>
          import('./pages/crf-glycomet/crf-glycomet.component').then(
            (m) => m.CrfGlycometComponent
          )
      },

      {
        path: 'crfjarla',
        loadComponent: () =>
          import('./pages/crf-jalra/crf-jalra.component').then(
            (m) => m.CrfJalraComponent
          )
      },

      {
        path: 'crfudapam',
        loadComponent: () =>
          import('./pages/crf-udapam/crf-udapam.component').then(
            (m) => m.CrfUdapamComponent
          )
      },

      {
        path: 'crfjalraod',
        loadComponent: () =>
          import('./pages/crf-jalraod/crf-jalraod.component').then(
            (m) => m.CrfJalraodComponent
          )
      },

      {
        path: 'crfjalraod2',
        loadComponent: () =>
          import('./pages/jalraod2/jalraod2.component').then(
            (m) => m.Jalraod2Component
          )
      },

      // { path: 'visit1', component: Visit1Component },

      // { path: 'visit2', component: Visit2Component },

      // { path: 'visit3', component: Visit3Component },

      // { path: 'visit4', component: Visit4Component },

      {
        path: 'visit1',
        loadComponent: () =>
          import('./pages/crf-jalraod/visit1/visit1.component').then(
            (m) => m.Visit1Component
          )
      },



      {
        path: 'visit2',
        loadComponent: () =>
          import('./pages/crf-jalraod/visit2/visit2.component').then(
            (m) => m.Visit2Component
          )
      },

      {
        path: 'visit3',
        loadComponent: () =>
          import('./pages/crf-jalraod/visit3/visit3.component').then(
            (m) => m.Visit3Component
          )
      },

      {
        path: 'visit4',
        loadComponent: () =>
          import('./pages/crf-jalraod/visit4/visit4.component').then(
            (m) => m.Visit4Component
          )
      },

      {
        path: 'visit5',
        loadComponent: () =>
          import('./pages/crf-jalraod/visit5/visit5.component').then(
            (m) => m.Visit5Component
          )
      },

      {
        path: 'visit6',
        loadComponent: () =>
          import('./pages/crf-jalraod/visit6/visit6.component').then(
            (m) => m.Visit6Component
          )
      },

      {
        path: 'LastVisit',
        loadChildren: () => import('./pages/crf-jalraod/jalraod/jalraod.module').then(m => m.JalraodModule)
      },
      {
        path: 'FirstVisit',
        loadChildren: () => import('./pages/crf-jalraod/visitfirst/visitfirst.module').then(m => m.VisitfirstModule)
      },

      


      {
        path: 'subject',
        loadComponent: () =>
          import('./pages/subject/subject.component').then(
            (m) => m.SubjectComponent
          )
      },

      
      
     
    ]
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
    ),
    resolve: {
      study: studyResolver
    }
  },
];
