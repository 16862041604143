export const environment = {
    production: true,
    url: 'https://mcrrealworldstudy.com/api/',
    uploadUrl: 'https://mcrrealworldstudy.com/api/uploads/',
    onboardingUrl: 'https://mcrrealworldstudy.com/api/uploads/onboarding/',
    addRegistrationUrl: 'https://mcrrealworldstudy.com/api/uploads/addRegistration/',
    drdocument: 'https://mcrrealworldstudy.com/api/uploads/drdocument',
    studyDoc: 'https://mcrrealworldstudy.com/api/uploads/studydoc',
    ctaCertificateUrl: 'https://mcrrealworldstudy.com/api/uploads/ctaCertificate',
    panFileUrl: 'https://mcrrealworldstudy.com/api/uploads/panFile',
    mrcUrl: 'https://mcrrealworldstudy.com/api/uploads/mrcFile',


    // For qa

    // url: 'https://qa.mcrrealworldstudy.com/api/',
    // uploadUrl: 'https://qa.mcrrealworldstudy.com/api/uploads/',
    // onboardingUrl: 'https://qa.mcrrealworldstudy.com/api/uploads/onboarding/',
    // addRegistrationUrl: 'https://qa.mcrrealworldstudy.com/api/uploads/addRegistration/',
    // drdocument: 'https://qa.mcrrealworldstudy.com/api/uploads/drdocument',
    // studyDoc: 'https://qa.mcrrealworldstudy.com/api/uploads/studydoc',
    // ctaCertificateUrl: 'https://qa.mcrrealworldstudy.com/api/uploads/ctaCertificate',
    // panFileUrl: 'https://qa.mcrrealworldstudy.com/api/uploads/panFile',
    // mrcUrl: 'https://qa.mcrrealworldstudy.com/api/uploads/mrcFile',


};
