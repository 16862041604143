import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestService } from './rest.service';
import { String } from 'typescript-string-operations';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private restService: RestService,private http: HttpClient) { }

  static readonly nodeProject: string = 'project/';
  static readonly NodeAddProject: string = ProjectService.nodeProject + 'addProject';
  static readonly nodeGetAllProject: string = ProjectService.nodeProject + 'getAllProject';
  static readonly nodeGetStudyByCode: string = ProjectService.nodeProject + 'getStudyByCode?studyCode={0}';
  // static readonly nodeGetStudyName: string = ProjectService.nodeProject + 'getAllStudyName';
  static readonly NodeDeleteStudy: string = ProjectService.nodeProject +'deleteStudy';
  static readonly nodeGetStudyByName: string = ProjectService.nodeProject + 'getStudyByName?studyName={0}';
  static readonly nodeGetStudyById: string = ProjectService.nodeProject + 'getStudyById?studyId={0}';
  static readonly nodeGetCrfUrlByStudyCode: string = ProjectService.nodeProject + 'getCrfUrlByStudyCode?studyCode={0}';



  public user: any;
  public userObservable: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  isImage(file: any) {
    if (file && file.name) {
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split('.').pop();
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp','svg','webp'];
      if (allowedExtensions.includes(fileExtension)) {
        return true;
      }
    }
    return false;
  }

  downloadFiles(filename: string): void {
    const url = `https://mcrrealworldstudy.com/api/uploads/addRegistration/${filename}`;
    window.open(url, '_blank');
  }

   public async addProject(user: any): Promise<any> {
    // LoaderComponent.showLoader();
    try {
      const res = await this.restService.postFormData(ProjectService.NodeAddProject, user);
      console.log(res)
      // LoaderComponent.hideLoader();
      return Promise.resolve(res);
    } catch (e) {
      // LoaderComponent.hideLoader();
      return Promise.reject(e);
    }
  }

  public async getStudyByCode(code: any): Promise<any> {
    try {
      const url = String.format(ProjectService.nodeGetStudyByCode, code);
      const res = await this.restService.get(url);
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCrfUrlByStudyCode(code: any): Promise<any> {
    try {
      const url = String.format(ProjectService.nodeGetCrfUrlByStudyCode, code);
      const res = await this.restService.get(url);
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getStudyByName(name: any): Promise<any> {
    try {
      const url = String.format(ProjectService.nodeGetStudyByName, name);
      const res = await this.restService.get(url);
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getStudyById(name: any): Promise<any> {
    try {
      const url = String.format(ProjectService.nodeGetStudyById, name);
      const res = await this.restService.get(url);
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getAllProject(): Promise<any> {
    try {
      const res = await this.restService.get(ProjectService.nodeGetAllProject);
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }


  public async deleteProject(study: any) {

    try {
      const body = {
        studyId: study
      };
      const res: any = await this.restService.post(ProjectService.NodeDeleteStudy, body);
      return Promise.resolve(res.data);
    } catch (e) {
      return Promise.reject(e);
    }

  }



  
}
