import { Injectable } from '@angular/core';
import { VexLayoutService } from '@vex/services/vex-layout.service';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor(private readonly layoutService: VexLayoutService, private authService: AuthService) {
     this.loadNavigation();
  }

  loadNavigation(): void {
    const res = this.authService.currentUserValue;
    if(res){
      console.log(res);
      if(res?.user?.isAdmin==1){
        this._items.next([
          {
            type: 'link',
            label: 'User',
            route: '/user',
            routerLinkActiveOptions: { exact: true }  
          },
          {
            type: 'link',
            label: 'Study',
            route: '/study',
            routerLinkActiveOptions: { exact: true }
          },
          {
            type: 'link',
            label: 'Doctors',
            route: '/doctor',
            routerLinkActiveOptions: { exact: true }
          },
        ]);
      }else if(res?.user?.userTypeId==2){
        this._items.next([
          // {
          //   type: 'link',
          //   label: 'Subjects',
          //   route: '/subject',
          //   routerLinkActiveOptions: { exact: true }  
          // },
        ]);
      }else{
        this._items.next([]);
      }
    }
     
      
    
  }
}
