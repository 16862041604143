<div class="flex-auto flex items-center container">
  <!--<a
    class="action flex-none"
    color="primary"
    href="//1.envato.market/0vkRO"
    id="get-vex"
    mat-flat-button>
    <mat-icon
      class="icon-sm ltr:mr-2 rtl:ml-2"
      svgIcon="mat:shopping_bag"></mat-icon>
    <span>Get MEDICLIN (Angular 17+)</span>
  </a>-->
  <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block ">
    MEDICLIN CR
  </div>
</div>
